import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFormSubmit, useNotify } from '@/hooks'
import { InputSection } from './InputSection'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  chatId: number | undefined
  disabled: boolean
}

export const EntryForm = ({ ...props }: IProps) => {
  const { t } = useTranslation()
  const [cleanImage, setCleanImage] = useState<boolean>(false)
  const inputRef = useRef<any>()
  const { showNotification } = useNotify()

  const methods = useForm({
    defaultValues: {
      body: '',
      image: null,
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
    setError,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onReset = () => {
    reset()
    setCleanImage(true)
    inputFocus()
  }

  const inputFocus = () => {
    // set input focus
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }

  useEffect(() => {
    onReset()
  }, [props.chatId])

  const isDisabled = useMemo(() => {
    // console.log('isDisabled isValid=' + isValid)
    return props.disabled || isSubmitting || !isValid
  }, [props.disabled, isSubmitting, isValid])

  const onSubmit: SubmitHandler<any> = async (data) => {
    // console.log('onSubmit body="' + data.body + '" image=' + data.image)
    setCleanImage(false)

    try {
      const { body, image } = data

      /*if (isDisabled && !image) {
        console.log('disabled and not image')
        return
      }*/

      const values: any = {
        body: body !== '' ? body : null,
        image,
      }

      await onFormSubmit(values)

      onReset()
    } catch (error) {
      showNotification({
        type: 'error',
        message: t('errorSendingChatMessage'),
      })
      inputFocus()
    }
  }

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)()
  }

  return (
    <>
      <FormProvider {...methods}>
        <InputSection onSubmit={onHandleSubmit} cleanImage={cleanImage} inputRef={inputRef} />
      </FormProvider>
    </>
  )
}
