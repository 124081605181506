import _ from 'lodash'
import { ChatModel, MessageType, Participant } from '@/features/chat/types/models'

export const createChatItemBasedOnMessage = (
  message: MessageType,
  chat: ChatModel,
  isCurrentChat = false
) => {
  const { chat_id, ...messageData } = message

  const isReceived = !messageData.is_my

  const result = {
    ...chat,
    id: chat_id,
    last_message: messageData,
    unread_count: isReceived && !isCurrentChat ? 1 : 0,
    participants: [] as Participant[],
  }
  if (chat.participants)
    chat.participants.forEach((participant) => {
      const { avatar, id, first_name, last_name, role } = participant
      result.participants.push({
        avatar,
        id,
        first_name,
        last_name,
        role,
      })
    })

  return result
}

export const updateChatItem = (
  item: ChatModel,
  message: MessageType,
  chat: ChatModel,
  isCurrentChat = false
) => {
  const { chat_id, ...messageData } = message || {}
  const isReceived = !messageData.is_my

  const result: any = {
    last_message: _.isEmpty(messageData) ? null : messageData,
  }

  if (isReceived) {
    result.participants = chat?.participants
    result.unread_count = isCurrentChat ? item.unread_count : item.unread_count + 1
  }

  return result
}
