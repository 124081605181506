import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { chatConversationMessagesSendPA } from '@/features/chat/store'
import { ChatModel } from '@/features/chat/types/models'
import { useNotify } from '@/hooks'
import { RootState } from '@/store'
import { EntryForm } from './EntryForm'
import useStyles from './EntryToolbar.styles'

interface IProps {
  messagesLoading: boolean
  activeChat: ChatModel | null
}

const EntryToolbarComponent = (props: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const dispatch = useDispatch()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    try {
      const params = {
        ...values,
      }

      const payload = {
        activeChat: props.activeChat,
        params,
      }

      await dispatch(chatConversationMessagesSendPA.request(payload))
    } catch (error: any) {
      const message = error?.message || t('error')
      //console.log('EntryToolbarComponent showNotification', message)
      showNotification({ type: 'error', message: message })
      throw error
    }
  }

  return (
    <div className={classes.root}>
      <EntryForm
        chatId={props.activeChat?.id}
        disabled={props.messagesLoading}
        onSubmit={onSubmit}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { loading: messagesLoading } = state.chat.conversation.messages.list

  return {
    messagesLoading,
    activeChat: state.chat.conversation.data.activeChat,
  }
}

export const EntryToolbar = connect(mapStateToProps)(EntryToolbarComponent)
